import * as clipboard from "clipboard-polyfill/text";

/**
 * Funkcia skopituje retazec do clipboard
 * @param string
 */
export const copyString = (string, text = "Skopírovaný text:") => {
  clipboard.writeText(string).then(
    () => alert(text),
    () => alert("Chyba: reťazec sa nepodarilo skopírovať")
  );
};
