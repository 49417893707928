/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */

import axios from 'axios';

const translateErrMsg = (msg) => {
  switch (msg) {
    case 'An authentication exception occurred.': return 'Pri overovaní došlo k chybe.';
    case 'Authentication credentials could not be found.': return 'Overovacie údaje neboli nájdené.';
    case 'Authentication request could not be processed due to a system problem.': return 'Požiadavok na overenie nemohol byť spracovaný kvôli systémovej chybe.';
    case 'Invalid credentials.': return 'Neplatné prihlasovacie údaje.';
    case 'Cookie has already been used by someone else.': return 'Cookie už bolo použité niekým iným.';
    case 'Not privileged to request the resource.': return 'Nemáte oprávnenie pristupovať k prostriedku.';
    case 'Invalid CSRF token.': return 'Neplatný CSRF token.';
    case 'No authentication provider found to support the authentication token.': return 'Poskytovateľ pre overovací token nebol nájdený.';
    case 'No session available, it either timed out or cookies are not enabled.': return 'Session nie je k dispozíci, vypršala jej platnosť, alebo sú zakázané cookies.';
    case 'No token could be found.': return 'Token nebol nájdený.';
    case 'Username could not be found.': return 'Prihlasovacie meno nebolo nájdené.';
    case 'Account has expired.': return 'Platnosť účtu skončila.';
    case 'Credentials have expired.': return 'Platnosť prihlasovacích údajov skončila.';
    case 'Account is disabled.': return 'Účet je zakázaný.';
    case 'Account is locked.': return 'Účet je zablokovaný.';
    case 'Too many failed login attempts, please try again later.': return 'Príliš mnoho neúspešných pokusov o prihlásenie. Skúste to prosím znovu neskôr.';
    case 'Invalid or expired login link.': return 'Neplatný alebo expirovaný odkaz na prihlásenie.';
    default: return  'Prihlásenie zlyhalo';
  }
};

const SyliusApiLogin = (el) => {
  const element = el;
  const signInButton = element.querySelector('.btn');
  const validationField = element.querySelector('.alert');
  const url = element.querySelector('form').getAttribute('action');
  const emailField = element.querySelector('input[name="_username"]');
  const passwordField = element.querySelector('input[name="_password"]');
  const csrfTokenField = element.querySelector('input[type="hidden"]');
  const csrfTokenName = csrfTokenField.getAttribute('name');

  signInButton.addEventListener('click', (e) => {
    e.preventDefault();
    element.querySelector('form').classList.add('loading');

    const params = new URLSearchParams();
    params.append('_username', emailField.value);
    params.append('_password', passwordField.value);
    params.append([csrfTokenName], csrfTokenField.value);

    axios.post(url, params)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        element.querySelector('form').classList.remove('loading');
        validationField.classList.remove('d-none');
        validationField.innerHTML = translateErrMsg(error.response.data.message);
      });
  });
};

export default SyliusApiLogin;
