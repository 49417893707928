/* eslint-env browser */
const companySwitcher = document.getElementById('sylius_checkout_address_companyData');

const parseKey = (key) => {
  return key.replace(/(_\w)/g, words => words[1].toUpperCase());
};

const findByName = (name, element) => {
  return element.querySelector(`[name*="[${parseKey(name)}]"`);
};

const resolveCompany = (choiceData) => {
  console.log(choiceData);
  const syliusCompanyData = document.querySelector('[data-js-address-book="sylius-company-data"]');
  companySwitcher.checked = true;
  syliusCompanyData.style.display = 'block';

  Object.entries(choiceData).forEach(([property, value]) => {
    console.log(property, value);
    const field = findByName(property, syliusCompanyData);

    if (field) {
      field.value = value;
    }
  });
  // SyliusAddressBook(syliusCompanyData);
};

const SyliusAddressBook = function SyliusAddressBook(el) {
  const element = el;
  const select = element.querySelector('.dropdown');
  //const changeEvent = new Event('change');

  select.querySelectorAll('.dropdown-item').forEach((item) => {
    item.addEventListener('click', (e) => {
      const choice = e.currentTarget;
      const choiceData = {};

      [...choice.attributes].forEach((attr) => {
        if (attr.name.startsWith('data-')) {
          const camelCased = attr.name.replace('data-', '').replace(/-([a-z])/g, g => g[1].toUpperCase());
          choiceData[camelCased] = attr.value;
        }
      });

      // const { provinceCode, provinceName } = choiceData;
      // const provinceContainer = select.closest('[data-js-address-book]').querySelector('.province-container');

      element.querySelectorAll('input, select').forEach((input) => {
        input.value = '';
      });

      Object.entries(choiceData).forEach(([property, value]) => {
        const field = findByName(property, element);

        // if (property.indexOf('countryCode') !== -1) {
        //   field.value = value;
        //   field.dispatchEvent(changeEvent);
        //
        //   const exists = setInterval(() => {
        //     const provinceCodeField = findByName('provinceCode');
        //     const provinceNameField = findByName('provinceName');
        //
        //     if (!provinceContainer.hasAttribute('data-loading')) {
        //       if (provinceCodeField && provinceCodeField.length !== 0 && (provinceCode !== '' || provinceCode != undefined)) {
        //         provinceCodeField.value = provinceCode;
        //
        //         clearInterval(exists);
        //       } else if (provinceNameField && provinceNameField.length !== 0 && (provinceName !== '' || provinceName != undefined)) {
        //         provinceNameField.value = provinceName;
        //
        //         clearInterval(exists);
        //       }
        //     }
        //   }, 100);
        // } else if (field) {
        //   field.value = value;
        // }

        if (field) {
          field.value = value;
        }
      });


      if (choiceData['company']) {
        resolveCompany(choiceData);
      }
    });
  });
};

export default SyliusAddressBook;
