// JS
import GLightbox from 'glightbox';
import axios from 'axios';
import 'bootstrap.native/dist/bootstrap-native-v4';

import $ from 'jquery';
global.$ = global.jQuery = $;

import 'autocomplete.js/dist/autocomplete.jquery';
import multiSelect from 'multiselect-dropdown';

import SyliusRating from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-rating';
import SyliusToggle from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-toggle';
import CustomSyliusAddToCart from './custom-sylius-add-to-cart';
import CustomSyliusApiLogin from './custom-sylius-api.login';
import SyliusRemoveFromCart from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-remove-from-cart';
import SyliusApiToggle from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-api-toggle';
import SyliusApiLogin from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-api-login';
// import SyliusVariantsPrices from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-variants-prices';
import CustomSyliusVariantsPrices from './custom-sylius-variant-prices';
import SyliusVariantImages from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-variant-images';
// import SyliusProvinceField from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-province-field';
import SyliusAddressBook from './custom-sylius-address-book';
import SyliusLoadableForms from '../../../../vendor/sylius/bootstrap-theme/assets/js/sylius-loadable-forms';
// import '../../../../vendor/sylius/bootstrap-theme/assets/js/index'; -> skopirovane na vyhodenie SyliusProvinceField

import '../scss/app.scss';
// import '../../../vendor/sylius/bootstrap-theme/assets/media/sylius-logo.png';
import '../../../../vendor/sylius/bootstrap-theme/assets/js/fontawesome';
import { removeClass } from "glightbox/src/js/utils/helpers";

import * as clipboard from "clipboard-polyfill/text";

import { copyString } from "./utils/copy-string";
// aby twig videl funkciu z webapacku
window.copyString = copyString;

// Global axios settings
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
axios.defaults.headers.post.accept = 'application/json, text/javascript, */*; q=0.01';
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';

document.addEventListener('DOMContentLoaded', () => {

	// Lightbox
	const glightbox = GLightbox({ selector: '.glightbox' });

	// Add to cart
	document.querySelectorAll('[data-js-add-to-cart="form"]')
		.forEach(el => CustomSyliusAddToCart(el));

	// Remove from cart
	document.querySelectorAll('[data-js-remove-from-cart-button]')
		.forEach(el => SyliusRemoveFromCart(el));

	// Province field
	// SyliusProvinceField();

	// Address book
	const syliusShippingAddress = document.querySelector('[data-js-address-book="sylius-shipping-address"]');
	if (syliusShippingAddress && syliusShippingAddress.querySelector('.dropdown')) {
		SyliusAddressBook(syliusShippingAddress);
	}
	const syliusBillingAddress = document.querySelector('[data-js-address-book="sylius-billing-address"]');
	if (syliusBillingAddress && syliusBillingAddress.querySelector('.dropdown')) {
		SyliusAddressBook(syliusBillingAddress);
	}

	// Variant prices
	CustomSyliusVariantsPrices();

	// Star rating
	document.querySelectorAll('[data-js-rating]').forEach((elem) => {
		new SyliusRating(elem, {
			onRate(value) {
				document.querySelector(`#sylius_product_review_rating_${value - 1}`).checked = true;
			},
		});
	});

	// Toggle and login from checkout
	if (document.querySelector('[data-js-login]')) {
		SyliusApiToggle(document.querySelector('[data-js-login="email"]'));
		SyliusApiLogin(document.querySelector('[data-js-login]'));
	}

  if (document.querySelector('[data-js-custom-login]')) {
		CustomSyliusApiLogin(document.querySelector('[data-js-custom-login]'));
	}

	// Toggle billing address on the checkout page
	document.querySelectorAll('[data-js-toggle]').forEach(elem => new SyliusToggle(elem));

	// Product images for variants
	if (document.querySelector('[data-variant-options], [data-variant-code]')) { new SyliusVariantImages(); }

	// Loadable forms
	SyliusLoadableForms();
});
// MENU SHOW / HIDE
let menu = $('.main-menu-wrap');
let menuOverlay = $('.main-menu-overlay');

$('.JS_menu-show').on('click', function(){
  if (!menu.hasClass('active')) {
    menu.addClass('active');
    menuOverlay.fadeIn();
    return false;
  }
});
menuOverlay.on('click', function(){
  menu.removeClass('active');
  $(this).fadeOut();
});
menu.on('click',function(e){
  e.stopPropagation();
});


// ADD TO CART PLUS/MINUS
$(() => {
  $('.count-wrap').find('a.minus').on('click', (elm) => {
    elm.preventDefault();
    const elInp = $(elm.target).closest('.count-wrap').find('input');
    const val = parseInt(elInp.val());
    elInp.val((val > 1) ? val - 1 : 1);
    return false;
  });

  $('.count-wrap').find('a.plus').on('click', (elm) => {
    elm.preventDefault();
    const elInp = $(elm.target).closest('.count-wrap').find('input');
    const val = parseInt(elInp.val());
    elInp.val((val > 0) ? val + 1 : 1);
    return false;
  });
});

$(() => {
  // autocomplet pre vyhladavanie
  const siteSearchElm = $('#site-search');
  const aut = siteSearchElm.autocomplete({
    hint: false,
  },
  [{
    minChar: 3,
    debounce: 300, // only request every 1/2 second
    source: function(query, cb) {
      $.ajax({
        url: siteSearchElm.data('url') + '?phrase=' + query,
      }).then(function(data) {
        data = data.map(o => {
          o.value = o.name;

          return o;
        });

        cb(data);
      });
    },
    templates: {
      suggestion: (suggestion) => {
        console.log(suggestion);
        const strike = (suggestion.originalPrice > 0 && suggestion.price !== suggestion.originalPrice) ? `<span class="strike">${suggestion.originalPriceFormatted}</span>` : '';
        const tierPrice = suggestion.tierPriceFormatter ? `<span class="tier-price">${suggestion.tierPriceFormatter}</span>` : '';

        return `<div class="search-item-wrap">
          <div class="img"><img src="${suggestion.image}" alt="${suggestion.name}"></div>
          <div class="content">
            <h6>${suggestion.name}</h6>
            <div class="code">Kód: ${suggestion.code}</div>
            <div class="price-wrap">
              ${strike}
              <span class="price">${suggestion.priceFormatted}</span>
              ${tierPrice}
            </div>
          </div>
        </div>`;
      }
    }
  }]);

  aut.on('autocomplete:selected', function(event, suggestion, dataset) {
    console.log('autocomplete:selected', aut, event, suggestion, dataset);
    window.location.href = siteSearchElm.data('home-url') + 'produkty/' + suggestion.slug;
  });

  aut.on('autocomplete:keyup', function(event, suggestion, dataset) {

    console.log('autocomplete:keyup', event, suggestion, dataset);
  });

  aut.on('autocomplete:keydown', function(event, suggestion, dataset) {

    console.log('autocomplete:keyup', event, suggestion, dataset);
  });

  // multiselect
  if ($("#balenie").length > 0) {
    const dropdown = new multiSelect(
      document.getElementById("balenie"),
      {
        maxVisibleOptions: 12,
        className: '',
      },
    );
  }
});

$(() => {
	const liElmActive = $('.sidebar-wrap .side-menu-content li.active');

	if (liElmActive.length) {
		const parentUlElm = liElmActive.parent('ul');

		if (!parentUlElm.hasClass('side-menu-content')) {
			const liElm = parentUlElm.parent('li').addClass('active');
			const parentUlElm2 = liElm.parent('ul');

			if (!parentUlElm2.hasClass('side-menu-content')) {
				parentUlElm2.parent('li').addClass('active');
			}
		}
	}

	$('.selfRedirect').on('click', (event) => {
		event.preventDefault();

		window.location.reload();
		return false;
	});
});

$(() => {
  if (!localStorage.getItem('cookieconsent')) {

    let el = '<div class="cookieconsent">Táto stránka používa cookies. Zotrvaním na stránke dávate súhlas s ich spracovaním.<button href="#" style="cursor: pointer;">Rozumiem</button></div>';
    $('body').append(el);

    $('.cookieconsent button').on('click', (e) => {
      e.preventDefault();
      document.querySelector('.cookieconsent').style.display = 'none';
      localStorage.setItem('cookieconsent', true);
    });
  }
});

